/* eslint-disable react-hooks/exhaustive-deps */
"use client";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LoginSignUpImage from "../../components/molecules/loginSingUpImage";
import SignUp from "../../components/organisms/signUp";
import Login from "../../components/organisms/login";
import Drawer from "../../components/molecules/drawer";
import CheckoutTNC from "../../components/molecules/checkoutTNC";
import PhoneNumber from "../../components/organisms/phoneNumber";
import VerifyDetails from "../../components/organisms/verifyDetails";
import { requestToGetWebFeatureControls } from "../../store/slices/webFeatureControls";
import { changePhoneNumber, requestToShowTNC } from "../../store/slices/loginSignupSlice";
import { setIsMobile } from "../../store/slices/common/appConfig";
import { unlockBody } from "../../utils/document";
import { initiateFus } from "../../store/slices/common/fus";
import useAnalytics from "../../analytics/useAnalytics";
import Header from "../../components/organisms/header";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../analytics/clevertapConstants";
import { usePathname } from "next/navigation";
import useLoginOmnitureSetup from "../../customHooks/omniture/useLoginOmnitureSetup";
import { PageTypes, setPageType } from "../../utils/fnpPageType";

/**
 * LoginPage component handles the rendering of different login/signup states.
 * It displays components conditionally based on the current state.
 * @returns {JSX.Element} The rendered LoginPage component.
 */
const LoginSignupPage = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { init, trackEvent } = useAnalytics();
  const { loginSignUp, bannerOnLoginScreen, loggedin } = useSelector(
    (state) => ({
      loginSignUp: state.loginSignUp,
      bannerOnLoginScreen: state?.webFeatureControls?.data?.bannerOnLoginScreen,
      loggedin: state.fus.loginDetail?.loggedin,
    }),
    shallowEqual,
  );

  const [previousPathName, setPreviousPathName] = useState("");
  const pathname = usePathname();
  const completePathName = typeof window !== "undefined" && window.location.href ? window.location.href : "";

  const pageViewedData = {
    complete_url: completePathName,
    clean_url: pathname,
    internal_source_type: previousPathName,
    internal_source: previousPathName,
    page_name: cleverTapCommonWords.LOGIN,
    page_type: cleverTapCommonWords.LOGIN,
  };

  useEffect(() => {
    /**
     *  handlePageShow check whether page is served from bfCache
     * @param {*} event
     */
    const handlePageShow = (event) => {
      if (event.persisted) {
        window.location.reload();
      }
    };

    window.addEventListener("pageshow", handlePageShow);

    const { referrer } = document;
    if (referrer) {
      setPreviousPathName(new URL(referrer).pathname);
    }
    dispatch(setIsMobile(isMobile));
    dispatch(requestToGetWebFeatureControls());
    if (isMobile) {
      dispatch(initiateFus());
    }

    setPageType(PageTypes.LOGIN);

    // Initialize analytic tools
    init();
    return () => {
      window.removeEventListener("pageshow", handlePageShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLoginOmnitureSetup();

  useEffect(() => {
    if (loggedin && previousPathName === "") {
      window.location.href = "/";
    }
    if (loggedin !== undefined) {
      setTimeout(() => {
        trackEvent(cleverTapCommonEvents.loginPageViewed, pageViewedData, ["clevertap"]);
      }, 2000);
    }
  }, [previousPathName, loggedin]);

  /**
   * Handles the click event to show the Terms and Conditions.
   */
  const onTNCClose = () => {
    dispatch(requestToShowTNC(false));
    dispatch(changePhoneNumber(false));
    unlockBody();
  };

  return (
    <>
      <div className="bg-grey-100 md:h-screen">
        {!isMobile && (
          <div className="bg-white-900">
            <Header isLoginPage={true} isMobile={isMobile} />
          </div>
        )}

        <div
          className={`mx-auto flex h-full w-full flex-col-reverse justify-center md:mb-[138px] md:mt-20 md:h-[538px] md:w-[940px] md:flex-row`}
        >
          <div
            className={`w-full rounded-t-3xl bg-white-900 md:m-0 md:h-full md:w-[58%] md:rounded-none ${!loginSignUp?.otpSuccess && isMobile ? "absolute top-[calc(38%-28px)]" : ""}`}
          >
            {!loginSignUp?.showRegister && !loginSignUp?.otpSuccess && <Login />}
            {loginSignUp?.showRegister && <SignUp completePathName={completePathName} pathname={pathname} />}
            {loginSignUp?.otpSuccess && <VerifyDetails />}
            <Drawer
              position={`${isMobile ? "bottom" : "right"}`}
              isOpen={loginSignUp?.showTNC}
              onDrawerClose={onTNCClose}
              styles="h-[90vh]"
            >
              <CheckoutTNC />
            </Drawer>
            <Drawer
              position={`${isMobile ? "bottom" : "right"}`}
              isOpen={loginSignUp?.changePhoneNumber}
              onDrawerClose={onTNCClose}
              styles={isMobile ? "h-[30vh]" : "h-full"}
            >
              <PhoneNumber />
            </Drawer>
          </div>
          {!(loginSignUp?.otpSuccess && isMobile) && (
            <div className="h-full min-h-[384px] w-full bg-white-900 md:h-full md:w-[42%]">
              {bannerOnLoginScreen && <LoginSignUpImage url={bannerOnLoginScreen} />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

LoginSignupPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default LoginSignupPage;
